import { WebViewerInstance } from '@pdftron/webviewer'
import { JobManifest } from '~/graphql/hooks/useJobManifestQuery'

export const headerCustomizations = (
  webviewerInstance: WebViewerInstance,
  jobManifest: JobManifest
) => {
  const iframeDoc = webviewerInstance.UI.iframeWindow.document

  /**
   *
   * @param currentPage
   * @description Update the page label in the header based on the current page number
   */
  const updatePageLabel = (currentPage: number) => {
    let label = `PAGE ${currentPage}`
    const pageNumber: HTMLDivElement | null = iframeDoc.querySelector(
      '[data-element="pageNumber"]'
    )

    if (!pageNumber) return

    const canRenderPageLabel =
      jobManifest.matches.length > 0 &&
      jobManifest.matches[currentPage - 1].revisedSheetNumber &&
      jobManifest.matches[currentPage - 1].revisedSheetTitle

    if (canRenderPageLabel) {
      label = `${
        jobManifest.matches[currentPage - 1].revisedSheetNumber
      } - ${jobManifest.matches[currentPage - 1].revisedSheetTitle}`
      if (label.length > 40) {
        label = label.substring(0, 40) + '...'
      }
      pageNumber.innerHTML = label
      return
    }

    pageNumber.innerHTML = label
  }

  webviewerInstance.UI.setHeaderItems((header) => {
    const headerItems = header
      .getItems()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((item: Record<string, any>) => item.element !== 'viewControlsOverlay')

    const nextpageButton = {
      type: 'actionButton',
      img: 'icon-chevron-right',
      onClick: () => {
        const pageCount = webviewerInstance.Core.documentViewer.getPageCount()
        const currentPage = webviewerInstance.Core.documentViewer.getCurrentPage()
        if (currentPage === pageCount) return

        webviewerInstance.Core.documentViewer.setCurrentPage(currentPage + 1, false)
        updatePageLabel(currentPage + 1)
      },
      dataElement: 'mbuePageNavigationButtonPrev',
      hidden: ['mobile']
    }

    const prevpageButton = {
      type: 'actionButton',
      img: 'icon-chevron-left',
      onClick: () => {
        const currentPage = webviewerInstance.Core.documentViewer.getCurrentPage()
        if (currentPage === 1) return

        webviewerInstance.Core.documentViewer.setCurrentPage(currentPage - 1, false)
        updatePageLabel(currentPage - 1)
      },
      dataElement: 'mbuePageNavigationButtonNext',
      hidden: ['mobile']
    }

    const pageNumber = {
      type: 'customElement',
      render: () => {
        const pageNumber = webviewerInstance.Core.documentViewer.getCurrentPage()
        let label = `PAGE ${pageNumber}`
        if (
          jobManifest.matches.length > 0 &&
          jobManifest.matches[pageNumber - 1].revisedSheetNumber &&
          jobManifest.matches[pageNumber - 1].revisedSheetTitle
        ) {
          label = `${
            jobManifest.matches[pageNumber - 1].revisedSheetNumber
          } - ${jobManifest.matches[pageNumber - 1].revisedSheetTitle}`
          if (label.length > 40) {
            label = label.substring(0, 40) + '...'
          }
        }
        return <div data-element="pageNumber">{label}</div>
      }
    }

    header.update([
      headerItems[0],
      headerItems[2],
      headerItems[1],
      headerItems[4],
      headerItems[6],
      headerItems[5],
      headerItems[3],
      prevpageButton,
      pageNumber,
      nextpageButton,
      headerItems[7],
      headerItems[8],
      headerItems[9],
      headerItems[10],
      headerItems[11]
    ])
  })

  const pageNumber: HTMLDivElement | null = iframeDoc.querySelector(
    '[data-element="pageNumber"]'
  )

  const prevNextButtons: NodeListOf<HTMLButtonElement> | null =
    iframeDoc.querySelectorAll('[data-element^="mbuePageNavigationButton"]')

  prevNextButtons.forEach((navButton) => {
    navButton.style.backgroundColor = '#1e1e1e'
    navButton.style.width = '32px'
    navButton.style.height = '32px'
    navButton.style.borderRadius = '4px'
    navButton.style.display = 'flex'
    navButton.style.justifyContent = 'center'
    navButton.style.alignItems = 'center'
    const icon: HTMLDivElement | null = navButton.querySelector('.Icon')
    if (icon) {
      icon.style.width = '18px'
      icon.style.height = '18px'
    }
  })

  if (pageNumber) {
    pageNumber.style.backgroundColor = '#1e1e1e'
    pageNumber.style.width = '294px'
    pageNumber.style.paddingInline = '12px'
    pageNumber.style.height = '32px'
    pageNumber.style.borderRadius = '4px'
    pageNumber.style.display = 'flex'
    pageNumber.style.justifyContent = 'center'
    pageNumber.style.alignItems = 'center'
    pageNumber.style.fontSize = '12px'
    pageNumber.style.overflow = 'hidden'
    pageNumber.style.textOverflow = 'ellipsis'
    pageNumber.style.textWrap = 'nowrap'
    pageNumber.style.whiteSpace = 'nowrap'
  }

  // Move all the page navigation controls into a wrapper to be centered all together
  const pageNavigationControlsWrapper: HTMLDivElement = document.createElement('div')
  pageNavigationControlsWrapper.classList.add('page-navigation-controls-wrapper')
  pageNavigationControlsWrapper.style.display = 'flex'
  pageNavigationControlsWrapper.style.gap = '4px'
  pageNavigationControlsWrapper.style.position = 'absolute'
  const controls = [prevNextButtons[0], pageNumber, prevNextButtons[1]]
  controls[0]?.parentNode?.insertBefore(pageNavigationControlsWrapper, controls[0])
  controls.forEach((control) => {
    pageNavigationControlsWrapper.appendChild(control!)
  })
  pageNavigationControlsWrapper.style.left = '50%'
  pageNavigationControlsWrapper.style.transform = 'translateX(-50%)'
}
