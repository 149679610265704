import { useEffect, useRef } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { env } from '~/env.ts'
import { Job } from '~/graphql/hooks/useReviewQuery'
import {
  setDefaultViewerSettings,
  addAnnotationTooltips,
  loadTextCompare
} from '~/utilities/webviewer'
import { useUser } from '~/graphql/hooks/useUser'
import { useJobManifestQuery, JobManifest } from '~/graphql/hooks/useJobManifestQuery'
import { headerCustomizations } from '~/utilities/webviewer/customizations'

const mapManifestToPageLabels = (manifest: JobManifest): string[] => {
  const pageLabels = manifest.matches.map((pageMatch) => {
    return pageMatch.revisedSheetNumber ?? (pageMatch.revisedPageIndex + 1).toString()
  })
  return pageLabels
}

const WebviewerComponent = ({ review }: { review: Job }) => {
  const { user } = useUser()
  const { projectId, reviewId } = useParams<{ projectId: string; reviewId: string }>()
  const [searchParams] = useSearchParams()
  const viewerDivRef = useRef<HTMLDivElement>(null)
  // const viewerInstanceRef = useRef<WebViewerInstance | null>(null)
  const isViewerInitialized = useRef<boolean>(false)

  const { jobManifest, loading: loadingJobManifest } = useJobManifestQuery(
    parseInt(projectId!),
    parseInt(reviewId!)
  )

  useEffect(() => {
    if (loadingJobManifest) return
    if (!user || !review || !viewerDivRef.current || !jobManifest?.data) return

    let webviewerKey: string = env.VITE_WEBVIEWER_DEMO_TOKEN ?? ''
    if (env.VITE_ENV === 'prod' && !user?.isAdmin) {
      webviewerKey = env.VITE_WEBVIEWER_TOKEN ?? ''
    }

    const initWebviewer = async () => {
      if (isViewerInitialized.current) return
      try {
        const webviewerInstance: WebViewerInstance = await WebViewer(
          {
            path: '/webviewer-f0ffc3c',
            licenseKey: webviewerKey,
            fullAPI: true,
            initialDoc: review.jobType === 'drawing' ? review.diffUri : review.originalUri
          },
          viewerDivRef.current!
        )

        // viewerInstanceRef.current = webviewerInstance
        headerCustomizations(webviewerInstance, jobManifest.data)
        setDefaultViewerSettings(webviewerInstance)

        if (review.jobType === 'text') {
          void loadTextCompare(webviewerInstance, review.originalUri, review.revisedUri)
        }

        webviewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
          const pageNumber = Number(searchParams.get('pageNumber')) || 1
          const { documentViewer } = webviewerInstance.Core
          if (jobManifest?.data) {
            const pageLabels = mapManifestToPageLabels(jobManifest?.data)
            webviewerInstance.UI.setPageLabels(pageLabels)
          }
          documentViewer.setCurrentPage(pageNumber, false)
          addAnnotationTooltips(webviewerInstance)

          const iframeDoc = webviewerInstance.UI.iframeWindow.document
          const pageNavOverlay: HTMLDivElement | null = iframeDoc.querySelector(
            '[data-element="pageNavOverlay"]'
          )
          if (pageNavOverlay) {
            pageNavOverlay.style.display = 'none'
          }
        })
      } catch (e) {
        console.warn('There was an error initializing the webviewer.', e)
      }
    }
    void initWebviewer()
    isViewerInitialized.current = true
  }, [user, review, searchParams, jobManifest, loadingJobManifest])

  return <div className="h-full w-full viewer" ref={viewerDivRef} />
}

export default WebviewerComponent
