import { gql, useLazyQuery, useMutation } from '@apollo/client'

interface Admin {
  jobs: (filter: AdminJobsFilter) => Promise<AdminJobRecord[]>
  rerunJob: (input: RerunJobInput) => Promise<boolean>
  assignJobToMe: (input: AssignJobToMeInput) => Promise<boolean>
  updateQualityStatus: (input: UpdateQualityStatusInput) => Promise<boolean>
  updateComment: (input: UpdateCommentInput) => Promise<boolean>
  copyToEval: (input: CopyToEvalInput) => Promise<boolean>
  loading: boolean
  rerunLoading: boolean
  assignJobToMeLoading: boolean
  updateQualityStatusLoading: boolean
  updateCommentLoading: boolean
  copyToEvalLoading: boolean
}

export interface AdminJobsFilter {
  name: string
  page: number
  limit: number
  filters: {
    employee: boolean
    deleted: boolean
    jobId: number
    email: string
    emailSearchType: string
    jobType: string
  }
}

export interface AdminJobRecord {
  id: number
  originalDesignId: number
  originalDesign: {
    id: number
    s3Key: string
    originalFilename: string
    pageCount: number
    fileSizeBytes: number
    createdAt: string
    updatedAt: string
  }
  revisedDesignId: number
  revisedDesign: {
    id: number
    s3Key: string
    originalFilename: string
    pageCount: number
    fileSizeBytes: number
    createdAt: string
    updatedAt: string
  }
  name: string
  status: number
  message: string
  comparisonId: number
  originalUri: string
  revisedUri: string
  diffUri: string
  createdAt: string
  completedAt: string | null
  deletedAt: string
  downloadUrls: {
    original: string
    revised: string
    thumbnail: string
    diff: string
    annotations: string
    annotationJson: string
    textDiff: string
  }
  project: {
    id: number
    orgId: number
    name: string
    thumbnail: string
    createdAt: string
    updatedAt: string
  }
  jobType: string
  matchType: number
  orgName: string
  containerTag: string
  rerun: number
  userEmail: string
  userId: number
  orgId: number
  internalQCReview: {
    id: number
    jobId: number
    reviewStatus: number
    qualityStatus: number
    reviewer: string
    comment: string
  }
}

export interface RerunJobInput {
  jobId: number
  containerTag: string
}

export interface AssignJobToMeInput {
  jobId: number
  reviewer: string
  reviewStatus: number
  qualityStatus: number
  comment: string
}

export interface UpdateQualityStatusInput {
  jobId: number
  qualityStatus: number
}

export interface UpdateCommentInput {
  jobId: number
  comment: string
}

export interface CopyToEvalInput {
  jobId: number
  pagesMatch: string
}

export interface AdminJobsResponse {
  report: {
    data: AdminJobRecord[]
  }
}

export interface RerunJobResponse {
  rerunJob: boolean
}

export interface AssignJobToMeResponse {
  updateInternalQCReview: boolean
}

export interface UpdateQualityStatusResponse {
  updateInternalQCReview: boolean
}

export interface UpdateCommentResponse {
  updateInternalQCReview: boolean
}

export interface CopyToEvalResponse {
  copyToEval: boolean
}

const ADMIN_JOBS_QUERY = gql`
  query ReportQuery($filter: FilterReportInput!) {
    report(filter: $filter) {
      name
      data
    }
  }
`

const RERUN_JOB_MUTATION = gql`
  mutation RerunJobMutation($input: RerunJobInput!) {
    rerunJob(input: $input)
  }
`

const UPDATE_QC_REVIEW_MUTATION = gql`
  mutation ($input: UpdateInternalQCReviewInput!) {
    updateInternalQCReview(input: $input) {
      id
    }
  }
`

const COPY_TO_EVAL_MUTATION = gql`
  mutation ($input: CopyToEvalInput!) {
    copyToEval(input: $input)
  }
`

export function useAdmin(): Admin {
  const [getAdminJobs, { loading }] = useLazyQuery(ADMIN_JOBS_QUERY, {
    fetchPolicy: 'network-only'
  })
  const [rerunJobMutation, { loading: rerunLoading }] = useMutation<RerunJobResponse>(
    RERUN_JOB_MUTATION,
    { fetchPolicy: 'network-only' }
  )
  const [assignJobToMeMutation, { loading: assignJobToMeLoading }] =
    useMutation<RerunJobResponse>(UPDATE_QC_REVIEW_MUTATION, {
      fetchPolicy: 'network-only'
    })
  const [updateQualityStatusMeMutation, { loading: updateQualityStatusLoading }] =
    useMutation<UpdateQualityStatusResponse>(UPDATE_QC_REVIEW_MUTATION, {
      fetchPolicy: 'network-only'
    })

  const [updateCommentMutation, { loading: updateCommentLoading }] =
    useMutation<UpdateCommentResponse>(UPDATE_QC_REVIEW_MUTATION, {
      fetchPolicy: 'network-only'
    })

  const [copyToEvalMutation, { loading: copyToEvalLoading }] =
    useMutation<CopyToEvalResponse>(COPY_TO_EVAL_MUTATION, {
      fetchPolicy: 'network-only'
    })

  const jobs = async (filter: AdminJobsFilter): Promise<AdminJobRecord[]> => {
    const result: { data: AdminJobsResponse } = await getAdminJobs({
      variables: { filter }
    })
    return result?.data?.report?.data || []
  }

  const rerunJob = async (input: RerunJobInput): Promise<boolean> => {
    await rerunJobMutation({
      variables: {
        input: input
      }
    })

    return true
  }

  const assignJobToMe = async (input: AssignJobToMeInput): Promise<boolean> => {
    await assignJobToMeMutation({
      variables: {
        input: input
      }
    })

    return true
  }

  const updateQualityStatus = async (
    input: UpdateQualityStatusInput
  ): Promise<boolean> => {
    await updateQualityStatusMeMutation({
      variables: {
        input: input
      }
    })

    return true
  }

  const updateComment = async (input: UpdateCommentInput): Promise<boolean> => {
    await updateCommentMutation({
      variables: {
        input: input
      }
    })

    return true
  }

  const copyToEval = async (input: CopyToEvalInput): Promise<boolean> => {
    await copyToEvalMutation({
      variables: {
        input: input
      }
    })

    return true
  }

  return {
    jobs,
    loading,
    rerunJob,
    assignJobToMe,
    updateQualityStatus,
    updateComment,
    copyToEval,
    rerunLoading,
    assignJobToMeLoading,
    updateQualityStatusLoading,
    updateCommentLoading,
    copyToEvalLoading
  }
}

export default useAdmin
